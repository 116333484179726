import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BUTTON_TEXTS } from 'src/app/_helpers/messages';

@Component({
  selector: 'app-action-row',
  templateUrl: './action-row.component.html',
  styleUrls: ['./action-row.component.scss']
})
export class ActionRowComponent implements OnInit {

  @Output() onCancel = new EventEmitter();
  @Output() onValidate = new EventEmitter();
  formButtonText = BUTTON_TEXTS;

  constructor() { }

  ngOnInit() {
  }

  save()
   {
     this.onValidate.emit();
  }

  cancel(){
    this.onCancel.emit();
  }

}
