import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { Identity, TokenPayLoad } from "../_models/apis/authentication"
import { AuthenticationService, IdentitiesService } from "../_services/apis/authentication"
import { AuthStoreService } from "../_services/ui/auth.store.service";
import { ERROR_MESSAGES } from "../_helpers/messages";
import { environment } from 'src/environments/environment';
import { Commons } from './commons';
import { CLAIMS_KEY } from './routes';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private identitiesService: IdentitiesService,
    private authStoreService: AuthStoreService,
    private router: Router,
    private common: Commons,
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    let encodedToken;
    let refreshToken;

    try {

      //On determine si on est appelé à partir d'une application (managersmag par ex) ou en standalone
      if (state.url.indexOf("?token=") >= 0 && state.root.queryParams.token.length>0) {
        //cas d'une application embarquée
        localStorage.setItem("token", state.root.queryParams.token);
        localStorage.setItem("refresh_token", state.root.queryParams.refresh_token);
        this.common.standalone=false;
      }
      else{
        //Standalone
        this.common.standalone=true;
      }

      encodedToken = localStorage.getItem("token");
      refreshToken = localStorage.getItem("refresh_token");

      //1 - No token from storage GOTO login page
      if (!encodedToken || encodedToken.length === 0) {
        localStorage.removeItem("token");
        this.router.navigate(["/login"], {
           queryParams: { returnUrl: state.url }
        });
        return false;
      }

      //2 - There is token in storage
      this.common.token = encodedToken;
      const decodedToken: any = jwt_decode(encodedToken);
      this.common.AuthUser=decodedToken.sub;
      const isActiveToken = decodedToken.exp > Date.now() / 1000;

      //3 - If token is not valid anymore GOTO Login page
      if (!isActiveToken) {
        localStorage.removeItem("token");
        this.router.navigate(["/login"], {
          queryParams: { returnUrl: state.url }
        });
        return false;
      }

      let user = decodedToken[CLAIMS_KEY.EMAIL].toLowerCase();
      this.common.SmagUser=decodedToken[CLAIMS_KEY.USERID];

      //4 - Check if user have a subscription to SmagManager
      if(!this.checkAccessRight(decodedToken)) {
        this.router.navigate(["/error"], {
          queryParams: { message: `User '${user}' is not authorized to access this application`, returnUrl: "/login" }
        });
        return false;
      }
      //User have a subscription (user_application) to access manager

      return true; // logged in so return true

    } catch (err) {
      localStorage.removeItem("token");
      this.router.navigate(["/error"], {
        queryParams: { message: err.message, returnUrl: "/login" }
      });
      return false;
    }
  }

  async checkAccessRight(token: any) {
    let accessRight = false;
    let identities;

    if(token[CLAIMS_KEY.IDENTITIES]){
      identities = token[CLAIMS_KEY.IDENTITIES];
    }else{
      //Get identities from API
      await this.authenticationService.getUserApplications().then(ids=>{
        identities = ids;
      });
    }

    //Check if user has access to the current application
    accessRight = identities && !!identities.find(
      (      application: { application_id: string; }) => {
        return application.application_id.toLowerCase() === environment.appId.toLowerCase() ;
    });

    return accessRight;
  }

  redirectToLoginPage = (state: RouterStateSnapshot) => {
    this.authStoreService.logout();
    this.router.navigate(["/login"], {queryParams: { returnUrl: state.url }});
  }

  checkLogin = (token: string, refreshToken: string, state: RouterStateSnapshot) => {

    try {

      //empty token
      if(!token) {
        this.redirectToLoginPage(state);
        return false;
      }

      const decodedToken: TokenPayLoad = jwt_decode(token);

      const isActive = this.isActiveToken(decodedToken);

      //token has expired
      if(!isActive) {
        this.redirectToLoginPage(state);
        return false;
      }

      //Verify user subscription to app
      const isSubscribed = this.isASubscribedUser(decodedToken);
      if(!isSubscribed) {
        const userEmail: string = decodedToken['https://smag.authentication.operational.com/email'];
        this.router.navigate(["/error"], { queryParams: { message: ERROR_MESSAGES.MISSING_SUBSCRIPTION(userEmail), returnUrl: "/login" } });
        return false;
      }

      return true;

    } catch(err) {
      this.router.navigate(["/error"], { queryParams: { message: err.message, returnUrl: "/login" } });
      return false;
    }
  }

  private isActiveToken = (token: TokenPayLoad): boolean => {
    return token.exp ? token.exp > Date.now() /1000: false;
  }

  private isASubscribedUser(token: TokenPayLoad): boolean | Observable<boolean> {

    const user = token['https://smag.authentication.operational.com/email'].toLowerCase();
    const userId = token['https://smag.authentication.operational.com/userId'].toLowerCase();
    let identities = token['https://smag.authentication.operational.com/identities']

    if(identities) {
      // prevously identities were in token
      return !!identities.find(identity => identity.application_id.toLowerCase() === environment.appId.toLowerCase())
    } else {
      // return an observable yes/no if user has right to access to the app
      return this.identitiesService.getCurrentUserSubscriptions()
        .pipe(map((identities: Identity[]): boolean => {
          identities = identities;
          const isSubscribe = !!identities.find(identity => identity.application_id.toLowerCase() === environment.appId.toLowerCase())

          if(!isSubscribe) {
            this.router.navigate(["/error"], {
              queryParams: { message: `User '${user}' is not authorized to access this application`, returnUrl: "/login" }
            });
          }

          return isSubscribe;
        }))
    }
  }
}
