import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthStoreService } from "../_services/ui/auth.store.service"
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authStore: AuthStoreService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // Clone the request and replace the original headers with
    // cloned headers, updated with the SmagAuthorization in http header
    const authorizationReq = req.clone({
      headers: req.headers.set('SmagAuthorization', `Bearer ${this.authStore.getToken()}`)
    });

    // send cloned request with header to the next handler.
    return next.handle(authorizationReq);
  }
}
