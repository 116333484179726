<header *ngIf="isStandalone" class="reporting-main-header">
    <div class="reporting-header-background">
        <div class="smagButtonContainer"></div>
      <div class=logo></div>
      <div id="trapeze"></div>
    </div>
</header>
<app-menu *ngIf="isStandalone"></app-menu>

<section class="reporting-content-section">
    <smag-buffer *ngIf="obs | async" [msg]="this.smagBufferService.message"></smag-buffer>
    <div class="content_layout" [ngClass]="{'standalone': isStandalone}">
        <router-outlet></router-outlet>
    </div>
</section>
