
export const BUTTON_TEXTS = {
    BUTTON_DELETE: "Supprimer",
    BUTTON_SAVE: "Sauvegarder",
    BUTTON_SHORT_SAVE: "Sauver",
    BUTTON_BACK: "Retour",
    BUTTON_CANCEL: "Annuler"
};

export const SEARCH_BOX_MESSAGES = {
    PLACEHOLDER: "Rechercher (appuyer sur la touche Entrée)"
  };
  export const INDICATOR_HTML_MESSAGES = {
  FIELD_PLACEHOLDER_ID: "Identifiant",
  FIELD_PLACEHOLDER_NAME: "Nom",
  FIELD_PLACEHOLDER_CODE: "Code",
  FIELD_PLACEHOLDER_INDICATOR_GROUP: "Groupe d'Indicateur"
}
export const VALIDATION_MESSAGES = {
    REQUIRED_MESSAGE: "Une valeur est attendue"
  };
export const ERROR_MESSAGES = {
    MISSING_SUBSCRIPTION: (user: string): string => `User '${user}' is not authorized to access this application`
}

export const INFO_MESSAGES = {
    LOADING_SUBMIT_MSG: "Les données sont en cours de sauvegarde",
    ERROR_SUBMIT_MSG: "Les données de l'écran sont invalides ou manquantes, veuillez vérifier les données",
    ERROR_UPLOAD_MSG: "L'upload n'a pas fonctionné, veuillez vérifier les données ainsi que la structure du fichier .csv",
    SUCCESS_SUBMIT_MSG: "Les données ont été sauvegarder avec succès",
    TOO_MANY_ITEMS_MSG: "5 fichiers maximum,par import"
}

export const INDICATORS_MESSAGES = {
    LOAD_DATA_INDICATORS: "Chargement des indicateurs en cours",
    LOAD_DATA_INDICATORS_GROUPS: "Chargement des groupes d'indicateurs en cours",
    ERROR_ADD_GROUP: "L'ajout au groupe n'a pas fonctionné, veuillez renseigner un groupe existant",
    ERROR_REQUIRED: "L'ajout n'a pas fonctionné correctement veuillez renseigner tous les champs requis"
}

export const COMMON_MESSAGES = {
    NO_RESULT: "Aucun Résultat, veuillez affiner votre recherche.",
}
