import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierComponent } from '../../_components/notifier/notifier.component';
import { Notification, NotificationType } from 'src/app/_models/ui/notification';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {
  private readonly TIME_OUT = 3000;
  constructor(private snackBar: MatSnackBar) { }

  push(notification: Notification) {

    let verticalPos: "top"| "bottom" | undefined;
    switch(notification.type){
      case NotificationType.success:
        verticalPos = "top";
        break;
      case NotificationType.error || NotificationType.warning:
        verticalPos = "bottom";
        break;
      default:
        break;
    }

    this.snackBar.openFromComponent(NotifierComponent, {
      data: notification,
      horizontalPosition: "center",
      verticalPosition: verticalPos,
      duration: this.TIME_OUT
    });

  }

}
