export class MenuEntry{
    constructor(i:number=-1, route:string="", displayName:string="",description:string="", sub?:MenuEntry[]){
        this.index=i;
        this.displayName=displayName;
        this.route=route;
        this.subMenu=sub;
        this.visible=true;
        this.description=description
    }
    index:number;
    route:string;
    displayName:string;
    visible?:boolean=true;
    subMenu?:MenuEntry[];
    description:string;
}
