import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { environment } from "../../../../environments/environment";

import { Identity } from "../../../_models/apis/authentication";
import { AUTHENTICATION_OPERATIONAL_ENDPOINTS} from "../../../_helpers/routes";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentitiesService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.API_URLS.smag_authentication_operational}${AUTHENTICATION_OPERATIONAL_ENDPOINTS.subscription}`
  }

  getCurrentUserSubscriptions(): Observable<Identity[]> {
     return this.http.get<Identity[]>(`${this.baseUrl}/current_user`)
  }
}
