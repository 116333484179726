<div class="manual-entry-content">
<div class="nav-reporting">
    <label class="nav-item-reporting active" id="manuelle" (click)="handleSelectModeDeSaisie($event, true, 'manuelle')">Saisie Manuelle</label>
    <label class="nav-item-reporting" id="upload" (click)="handleSelectModeDeSaisie($event, false, 'upload')">Import csv</label>
    <div class="selection left"></div>
</div>
<div class = "reporting-panel" *ngIf="this.menuToShow == 'upload'">
    <app-uploads></app-uploads>
</div>
<div class ="reporting-panel" *ngIf="this.menuToShow == 'manuelle'">
    <app-indicators></app-indicators>
</div>
</div>
