<div [formGroup]="indicatorFormGroup" (mouseover)="isHover=true" (mouseout)="isHover=false"class="indicator">
  <app-monthly-date-picker (keydown.enter)="$event.preventDefault()" [selectedDate]="this.indicatorFormGroup.get('selectedDate')" ></app-monthly-date-picker>
  <mat-form-field appearance="fill">
    <mat-label>Type de Client</mat-label>
    <mat-select (selectionChange)="handleClientTypeSelection($event)" [formControl]="this.indicatorFormGroup.get('selectedClientType')">
      <mat-option *ngFor="let clientType of clientTypes" [value]="clientType.Id">
        {{clientType.Name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Client</mat-label>
    <mat-select (click)="handleClientSelection($event)" [formControl]="this.indicatorFormGroup.get('selectedClient')">
      <mat-option *ngFor="let client of filteredClients" [value]="client.CompanyId">
        {{client.Name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Solution</mat-label>
    <mat-select (selectionChange)="handleSolutionSelection($event)" [formControl]="this.indicatorFormGroup.get('selectedSolution')">
      <mat-option *ngFor="let solution of solutions" [value]="solution.Id">
        {{solution.Name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Application</mat-label>
    <mat-select (click)="handleApplicationSelection($event)" [formControl]="this.indicatorFormGroup.get('selectedApplication')">
      <mat-option *ngFor="let app of filteredApplications" [value]="app.Id">
        {{app.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Groupe d'indicateur</mat-label>
    <mat-select (selectionChange)="handleIndicatorGroupSelected($event)" [formControl]="this.indicatorFormGroup.get('selectedIndicatorGroup')">
      <mat-option *ngFor="let indicatorGroup of indicatorGroups" [value]="indicatorGroup.Id">
        {{indicatorGroup.Name}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="this.indicatorFormGroup.get('selectedIndicatorGroup').hasError('required')">
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Indicateur</mat-label>
    <mat-select (click)="handleIndicateurSelection($event)" [formControl]="this.indicatorFormGroup.get('selectedIndicator')">
      <mat-option *ngFor="let indicator of filteredIndicators" [value]="indicator.Id">
        {{indicator.Name}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="this.indicatorFormGroup.get('selectedIndicator').hasError('required')">
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Type de mesure</mat-label>
    <mat-select [formControl]="this.indicatorFormGroup.get('selectedMeasureType')">
      <mat-option *ngFor="let measure of measures" [value]="measure.Code">
        {{measure.Name}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="this.indicatorFormGroup.get('selectedMeasureType').hasError('required')">
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" (keydown.enter)="$event.preventDefault()">
    <input type="number" matInput [formControl]="this.indicatorFormGroup.get('selectedValue')" placeholder="Valeur">
    <mat-error *ngIf="this.indicatorFormGroup.get('selectedValue').hasError('required')">
    </mat-error>
  </mat-form-field>
  <div class="actions" [style.visibility]="isHover ? 'visible' : 'hidden'">
    <app-icon-btn [iconPath]="binIconPath" (click)="handleDeletion($event)"></app-icon-btn>
    <app-icon-btn [iconPath]="copyIconPath" (click)="handleCopy($event)"></app-icon-btn>
  </div>
</div>
