<mat-toolbar class="reporting-menu">
    <ng-container>
            <div id="home">
                    <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="5rem" viewBox="0 0 48 48"><defs><style>.a{fill:none;}.b{fill:#222;}</style></defs><rect class="a" width="48" height="48"/><path class="b home" d="M17.2,34.655v-10h8v10a2.006,2.006,0,0,0,2,2h6a2.006,2.006,0,0,0,2-2v-14h3.4a.992.992,0,0,0,.66-1.74L22.538,3.855a2.015,2.015,0,0,0-2.68,0L3.138,18.915a1,1,0,0,0,.66,1.74H7.2v14a2.006,2.006,0,0,0,2,2h6A2.006,2.006,0,0,0,17.2,34.655Z" transform="translate(2.802 4.345)"/></svg>
            </div>
    </ng-container>
    <ng-container *ngFor="let menuItem of displayableMenu; let idx=index;">
            <div (click)="activateMenu(menuItem.index,menuItem.route, false)" class="reporting-menu-item">{{menuItem.displayName}}</div>
    </ng-container>
    <div class="marker"></div>
</mat-toolbar>
