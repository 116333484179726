  import { Component, OnInit } from '@angular/core';

  @Component({
    selector: 'app-manual-entry',
    templateUrl: './manual-entry.component.html',
    styleUrls: ['./manual-entry.component.scss']
  })

  export class ManualEntryComponent implements OnInit {

    public menuToShow: String = "manuelle";

    constructor() { }

    ngOnInit(): void {
    }
    private findSelectionInDom(nodes: any) {
      let selection;
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].classList.contains("selection")) {
          selection = nodes[i];
          break;
        }
      }
      return selection;
    }
    private resetActivation(nodes: any) {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].classList.contains("active")) {
          nodes[i].classList.remove("active");
        }
      }
    }
    private activation(node: any) {
      if (node) node.classList.add("active");
    }
    private setPosition(selectionElmt: any, selectedItem: string) {
      if (selectedItem == "manuelle") {
        selectionElmt.classList.add("left");
        selectionElmt.classList.remove("right");
      }
      else if(selectedItem == "upload") {
        selectionElmt.classList.add("right");
        selectionElmt.classList.remove("left");
      }
    }
    handleSelectModeDeSaisie(evt: any, isLeft: boolean, selectedItem: string)
    {
      const selectedNavItem = evt.target;
      const selection = this.findSelectionInDom(
        selectedNavItem.parentNode.children
      );
      this.resetActivation(selectedNavItem.parentNode.children);
      this.activation(selectedNavItem);
      if (selection) {
        this.setPosition(selection, selectedItem);
      }
      if(selectedItem == 'upload' && this.menuToShow != 'upload'){
        this.menuToShow = "upload";
      }
      else if(selectedItem == 'manuelle' && this.menuToShow != 'manuelle'){
        this.menuToShow = "manuelle";
      }
      else if(selectedItem == 'create' && this.menuToShow != 'create'){
        this.menuToShow = "create";
      }
    }
    public navigate = (itemName: string) => {
      const foundItem = document.getElementById(
        "nav-item-" + itemName.toLowerCase()
      );
      if (!foundItem) return;
      foundItem.click();
    };
  }
