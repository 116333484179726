import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

import { ClientType } from "../../../_models/apis/party";
import { PARTY_OPERATIONAL_ENDPOINTS } from "../../../_helpers/routes";
import { PaginatedResponse } from 'src/app/_models/_base/generics';


@Injectable({
  providedIn: 'root'
})
export class ClientTypesService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.API_URLS.smag_party_operational}${PARTY_OPERATIONAL_ENDPOINTS.client_types}`
  }

  getOld() {
    const url = this.baseUrl;

    return this.http.get(url).toPromise()
      .then(res => <ClientType[]>res)
      .then(data => {
        data.sort((n1,n2) => {
          let res=0
          res =(n1.Name.toLocaleLowerCase() > n2.Name.toLocaleLowerCase())?1:-1
          return res;
        });
        return data;}
        )
  }

  get = (limit: number = 25, offset: number = 0): Observable<PaginatedResponse<ClientType>> => {

    //added query params to the http request
    const httpOptions = {
      params: new HttpParams()
        .set("limit", limit.toString())
        .set("offset", offset.toString())
    };

    return this.http.get<PaginatedResponse<ClientType>>(this.baseUrl, httpOptions);
  }


}
