import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Client } from "../../../_models/apis/party";
import { PaginatedResponse } from "../../../_models/_base/generics";
import { environment } from "../../../../environments/environment"
import { PARTY_OPERATIONAL_ENDPOINTS } from "../../../_helpers/routes";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.API_URLS.smag_party_operational}${PARTY_OPERATIONAL_ENDPOINTS.clients}`
  }

  get = (limit: number = 25, offset: number = 0): Observable<PaginatedResponse<Client>> => {

    //added query params to the http request
    const httpOptions = {
      params: new HttpParams()
        .set("limit", limit.toString())
        .set("offset", offset.toString())
    };

    return this.http.get<PaginatedResponse<Client>>(this.baseUrl, httpOptions);
  }

}
