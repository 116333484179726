import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDatepickerModule,  } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { ButtonModule } from "@smag-cloud/smag-components";
import { httpInterceptorProviders,  } from "./_interceptors";
import { handlers } from "./_handlers";
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { IndicatorsComponent } from './_components/indicators/indicators.component';
import { IndicatorComponent } from './_components/indicator/indicator.component';
import { LoginComponent } from './_components/login/login.component';
import { MonthlyDatePickerComponent } from './_components/monthly-date-picker/monthly-date-picker.component';
import { FormHeaderComponent } from './_components/form-header/form-header.component';
import { IconBtnComponent } from './_components/icon-btn/icon-btn.component';
import { CallbackComponent } from './_components/callback/callback.component';
import { AuthGuard } from './_helpers/auth.guard';
import { NotifierComponent } from './_components/notifier/notifier.component';
import { ErrorComponent } from './_components/error/error.component';
import { MainComponent } from './_components/main/main.component';
import { SmagBufferModule} from "@smag-cloud/smag-components";
import { UploadsComponent } from './_components/uploads/uploads.component';
import { UploadComponent } from './_components/upload/upload.component';
import { MenuComponent } from './_components/menu/menu.component';
import { CreateIndicatorComponent } from './_components/create-indicator/create-indicator.component';
import { IndicatorDetailsComponent } from './_components/create-indicator/indicator-details/indicator-details.component';
import { FilterComponent } from './_components/filter/filter.component';
import { InfolistComponent } from './_components/infolist/infolist.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './_helpers/CustomMatPaginatorIntl';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActionRowComponent } from './_components/action-row/action-row.component';
import { ManualEntryComponent } from './_components/manual-entry/manual-entry.component';
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ReportsComponent } from './_components/reports/reports.component';

@NgModule({
  declarations: [
    AppComponent,
    IndicatorsComponent,
    IndicatorComponent,
    LoginComponent,
    MonthlyDatePickerComponent,
    FormHeaderComponent,
    IconBtnComponent,
    CallbackComponent,
    NotifierComponent,
    ErrorComponent,
    MainComponent,
    UploadsComponent,
    UploadComponent,
    MenuComponent,
    CreateIndicatorComponent,
    IndicatorDetailsComponent,
    FilterComponent,
    InfolistComponent,
    ActionRowComponent,
    ManualEntryComponent,
    ReportsComponent,
  ],
  imports: [
    BrowserModule,
    MatToolbarModule,
    MatMenuModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    ButtonModule,
    MatSnackBarModule,
    SmagBufferModule,
    MatPaginatorModule,
    MatTooltipModule
  ],
  providers: [
    AuthGuard,
    httpInterceptorProviders,
    handlers,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }, {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
