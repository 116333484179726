import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconRessource } from "../../_models/ui/icon"
import { BUTTON_TEXTS } from '../../_helpers/messages';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  btnTexts = BUTTON_TEXTS;
  message: string = "";
  returnUrl: string="";

  public backIcon: IconRessource = {
    label: "Ico_ChevronGauche.svg",
    path: "https://smagstorage.blob.core.windows.net/images/SmagAccount/Ico_ChevronGauche.svg"
  };

  constructor(private route: ActivatedRoute,private router: Router ) { }

  ngOnInit() {
    this.message = this.route.snapshot.queryParamMap.get('message')?? "";
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')?? "";

  }

  back(): void{
    this.router.navigate([this.returnUrl]);
  }

}
