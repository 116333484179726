
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable()
export class ApimInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // Clone the request and replace the original headers with
    // cloned headers, updated with the apim subscription key.
    const apimReq = req.clone({
      headers: req.headers.set('Ocp-Apim-Subscription-Key', environment.ApiManagerKey)
    });

    // send cloned request with header to the next handler.
    return next.handle(apimReq);
  }
}
