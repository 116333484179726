// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var window: any;

 //window.env = {};
 //window["env"]["API_URLS"] = {};

export const environment = {
  name: window["env"]["name"] || 'dev',
  production: window["env"]["production"] || false,
  ApiManagerKey: window["env"]["ApiManagerKey"] || '57b5332a5d9e40d9bcad6a294558305f',
  appId: window["env"]["appId"] || '8b99c2aa-9524-4b33-9f19-9722cf343dbb',
  SMAG_BUTTON_SCRIPT : window["env"]["SMAG_BUTTON_SCRIPT"]||'https://dev-smag-party-button-app-v1.azurewebsites.net/Scripts/SmagButton.js?',
  API_URLS: {
    smag_application_operational: window["env"]["API_URLS"]["smag_application_operational"] || 'https://dev-smag-application-api-management.azure-api.net/application-operational-extended/v1/',
    smag_party_operational: window["env"]["API_URLS"]["smag_party_operational"] || 'https://dev-smag-application-api-management.azure-api.net/party-operational-extended/v1/',
    smag_authentication_operational: window["env"]["API_URLS"]["smag_authentication_operational"] || 'https://dev-smag-application-api-management.azure-api.net/authentication-operational-extended/v1/',
    smag_reporting_operational: window["env"]["API_URLS"]["smag_reporting_operational"] || 'https://api-cloud-reporting-dev.caas-dev.smag.tech/'
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
