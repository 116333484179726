import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment"
import { REPORTING_OPERATIONAL_ENDPOINTS } from "../../../_helpers/routes";
import { PaginatedResponse } from "../../../_models/_base/generics";
import { Measure } from "../../../_models/apis/reporting";

@Injectable({
  providedIn: 'root'
})
export class MeasuresService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.API_URLS.smag_reporting_operational}${REPORTING_OPERATIONAL_ENDPOINTS.measures}`
  }

  get(): Observable<Measure[]> {
    return this.http.get<PaginatedResponse<Measure>>(this.baseUrl).pipe(map(x => x.Results as Measure[]));
  }
}
