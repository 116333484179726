<form [formGroup]="indicatorsForm" class="subscriptions-container" (ngSubmit)="handleSubmit()">
        <div formArrayName="indicatorFormArray" class="indicator-items" >
            <app-form-header></app-form-header>
            <app-indicator
                *ngFor="let indicator of indicatorFormArray.controls; let idx = index;"
                [formGroupName]="idx"
                [indicatorFormGroup]="indicator"
                [identifier]="idx"
                [clients]="clients"
                [clientTypes]="clientTypes"
                [applications]="applications"
                [solutions]="solutions"
                [indicators]="indicators"
                [indicatorGroups]="indicatorGroups"
                [measures]="measures"
                (onDelete)="handleDelete($event)"
                (onCopy)="handleCopy($event)">
            </app-indicator>
            <div class="add-item"><link-btn class="cancel-btn" text="+ Ajouter une ligne" (click)="handleAdd($event)"></link-btn></div>
        </div>
        <div class="actions">
            <link-btn class="cancel-btn" text="Annuler" (click)="handleCancel($event)"></link-btn>
            <primary-btn type="submit" text="Sauver"></primary-btn>
        </div>
</form>
