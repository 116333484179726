import { Guid } from "guid-typescript";
import { BaseEntity } from "../_base/generics"
export class Indicator {
    Id: string = Guid.createEmpty().toString();
    Name: string = "";
    Code: string = "";
}

export class IndicatorGroup {
    Indicators: string[] = [];
    Id: Guid = Guid.createEmpty();
    Name: string = "";
    Code: string = "";
}
export interface Report {
    Period: string;
    Company: string;
    User: string;
    Solution: string;
    Application: string;
    IndicatorGroup: string;
    Indicator: string;
    Measure: string;
    Numerator: number;
    Denominator: number;
}
export interface Measure extends BaseEntity<Guid> {
    Description: string;
}
