import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './_components/callback/callback.component';
import { ErrorComponent } from './_components/error/error.component';
import { LoginComponent } from './_components/login/login.component';
import { AuthGuard } from "./_helpers/auth.guard";

const routes: Routes = [
  { path: '',   redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    loadChildren: () => import('src/app/_modules/main/main.module').then(m => m.MainModule),
    canActivate: [ AuthGuard ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'error', component: ErrorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
