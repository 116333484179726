<div class="search-box">
  <input
    class="search-input"
    placeholder="{{this.customplaceholder}}"
    type="text"
    (keyup.enter)="handleSubmit($event)"
    (change)="handleChange($event)"
/>
<div class="search-icon" (click)=handleSubmit($event)></div>
</div>
