import { Injectable } from "@angular/core";
@Injectable({
  providedIn: 'root'
})
export class Commons {
  private _standalone:boolean=true;
  private _token: string="";
  private _authUser="";
  private _smagUser="";
constructor() { }

  //Custom maxnumber
  public get max_number_value():number{
    return 9999999;
  }
  //standalone property
  public get standalone(): boolean {
    return this._standalone;
  }
  public set standalone(value: boolean) {
    this._standalone = value;
  }

  //token Property
  public get token(): string {
    return this._token;
  }
  public set token(value: string) {
    this._token = value;
  }
    //User identifier in authentication domain
    public get AuthUser(): string {
      return this._authUser;
    }
    public set AuthUser(value: string) {
      this._authUser = value;
    }

    //User identifier in smag doamin
    public get SmagUser(): string {
      return this._smagUser;
    }
    public set SmagUser(value: string) {
      this._smagUser = value;
    }
}
