import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthStoreService } from "../../_services/ui/auth.store.service";

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStore: AuthStoreService
  ) { }

  ngOnInit(): void {
    this.getTokenRefreshTokenFromUri(this.route);
    const url = this.router.createUrlTree(['main']);
    window.open(url.toString(), '_top');
  }

  private getTokenRefreshTokenFromUri(currentRoute: ActivatedRoute): void {
    const token = currentRoute.snapshot.queryParamMap.get('token');
    const refreshToken = currentRoute.snapshot.queryParamMap.get('refresh_token');
    token ? this.authStore.login(token) : this.authStore.logout();

    if(refreshToken) this.authStore.refreshConnection(refreshToken);

  }
}
