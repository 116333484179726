import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-btn',
  templateUrl: './icon-btn.component.html',
  styleUrls: ['./icon-btn.component.scss']
})
export class IconBtnComponent implements OnInit {

  @Input() iconPath: string = "https://smagstorage.blob.core.windows.net/images/icon/Ico_Dupliquer.svg";

  public iconStyles: any;

  constructor(){ }

  ngOnInit() {
    this.iconStyles = {
      "mask-image": `url(${this.iconPath})`,
      "-webkit-mask-image": `url(${this.iconPath})`,
      "-webkit-mask-repeat": "no-repeat",
      "-webkit-mask-position": "center",
      "-webkit-mask-size": "3rem",
      "mask-repeat": "no-repeat",
      "mask-position": "center",
      "mask-size": "3rem"
    }
  }

}
