import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    previousPageLabel: string;
    nextPageLabel: string;
    itemsPerPageLabel: string;
    getRangeLabel: (page: number, pageSize: number, length: number) => string;
    constructor() {
        super();
        this.nextPageLabel = 'Page suivante';
        this.previousPageLabel = 'Page précédente';
        this.itemsPerPageLabel = 'Nombre d\'éléments par page: ';
        this.getRangeLabel= (page: number, pageSize: number, length: number) => { if (length == 0 || pageSize == 0) { return `0 sur ${length}`; } length = Math.max(length, 0); const startIndex = page * pageSize; const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize; return `${startIndex + 1} – ${endIndex} sur ${length}`; }
    }
}
