import { Component, OnInit } from "@angular/core";
import { SmagBufferService } from "@smag-cloud/smag-components";
import { INFO_MESSAGES } from "src/app/_helpers/messages";
import { NotificationType } from "src/app/_models/ui/notification";
import { ReportsService } from "src/app/_services/apis/reporting";
import { NotifierService } from "src/app/_services/ui/notifier.service";

@Component({
    selector: 'app-uploads',
    templateUrl: './uploads.component.html',
    styleUrls: ['./uploads.component.scss']
  })
  export class UploadsComponent implements OnInit {
    public readonly uploadIconPath: string = "https://smagstorage.blob.core.windows.net/images/icon/Ico_Upload_File.svg";
    filesToUpload: FormData = new FormData();
    inputFiles: File[] = [];
    filesNames: String[] = [];
    constructor(private reportsService: ReportsService,
      private notifierService: NotifierService,
      private bufferService: SmagBufferService){
    }

    ngOnInit(): void {
    }
    onInputClick = ( event: any ) => {
      const element = event.target as HTMLInputElement;
      element.value = '';
      this.inputFiles = [];
  }
    handleDeleteCSV (name: String) {
      var result = [];
      for (let index = 0; index < this.inputFiles.length; index++) {
        this. inputFiles[index].name.toString().toLowerCase() != name.toString().toLowerCase() ? result.push(this.inputFiles[index]) : "";
      }
      this.inputFiles = result;
      this.filesNames = this.filesNames.filter(fileName => fileName != name);
    }
    handleSubmitCSV() {
      if (this?.inputFiles?.length ?? 0 != 0){
        this.filesToUpload = new FormData();
        for (let index = 0; index < this.inputFiles.length; index++) {
          this.filesToUpload.set(this.inputFiles[index].name.split(".")[0] + "-" + Date.now() + ".csv",
          new Blob([this.inputFiles[index]], { type: 'text/csv' }),
          this.inputFiles[index].name);
        }
        this.bufferService.activate(INFO_MESSAGES.LOADING_SUBMIT_MSG);
        this.reportsService.uploadReports(this.filesToUpload).subscribe(
          response => {
            this.notifierService.push({message: INFO_MESSAGES.SUCCESS_SUBMIT_MSG, type: NotificationType.success});
            this.filesToUpload = new FormData();
            this.filesNames = [];

            this.bufferService.reset();
          },
          error => {
            this.notifierService.push({message: error.error, type: NotificationType.error});
            this.bufferService.reset();
          });
      }
    }
    handleFileInput(evt: any){
      if(evt.target.files && evt.target.files.length > 0) {
        if (evt.target.files.length > 5){
          this.notifierService.push({message: INFO_MESSAGES.TOO_MANY_ITEMS_MSG, type: NotificationType.error});
        }
        else {
          this.filesToUpload = new FormData();
          this.filesNames = [];
          for (let index = 0; index < evt.target.files.length; index++) {
            this.filesNames.push(evt.target.files[index]?.name ?? "");
            this.inputFiles.push(evt.target.files[index] as File);
          }
        }
      }
    }
  }
