import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../_services/apis/authentication/authentication.service";
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginUrl: SafeResourceUrl = "";
  constructor(private authenticationService: AuthenticationService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.authenticationService.getLoginSignUpPage()
      .subscribe(loginUrl => this.loginUrl = this.sanitizer.bypassSecurityTrustResourceUrl(loginUrl));
  }

}
