import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import $ from 'jquery';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { COMMON_MESSAGES } from 'src/app/_helpers/messages';
import { KeyEntry } from './models/KeyEntry';

@Component({
  selector: 'app-infolist',
  templateUrl: './infolist.component.html',
  styleUrls: ['./infolist.component.scss']
})

export class InfolistComponent implements OnInit {

  @Input() entries: Array<Object> = new Array<Object>();
  @Input() keys: Array<any> = new Array<any>();
  @Input() withTooltip:boolean=false;
  @Input() withSearch:boolean = false;
  @Input() withSort:boolean = false;
  @Input() withPaging:boolean = false;
  @Input() withDelete:boolean = false;
  @Input() withEdition:boolean = false;
  @Input() withNoResultMessage:boolean = false;
  @Input() withExcelExport:boolean=false;
  @Input() noResultMessage:string = COMMON_MESSAGES.NO_RESULT;
  @Input() inputLengthInDB: number = 0;
  @Input() remarks: string="";

  @Output() onDelete = new EventEmitter();
  @Output() onEdition = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  @Output() onFilterChange = new EventEmitter<string>();
  @Output() onSort = new EventEmitter<Object>();
  @Output() onPaging = new EventEmitter();
  @Output() onExportToExcel = new EventEmitter();

  @ViewChild(MatPaginator,  {static: false}) paginator!: MatPaginator;
  @ViewChildren('subscriptionlistchildren') subscriptionchildren: QueryList<any> = new QueryList<any>();

  displayNoContentPanel:boolean=false;
  currentSelectionIndex:number=-1;
  filteredEntries: Array<any> = new Array<any>();
  selectedDataRow: Object = new Object;

  styles: object = {};


  private _bufferVisible: boolean=false;
  public get bufferVisible(): boolean {
    return this._bufferVisible;
  }
  public set bufferVisible(value: boolean) {
    this._bufferVisible = value;
  }


  bufferMessage: string = "";

  constructor(private cdRef : ChangeDetectorRef) { }

  ngOnInit() {
    this.bufferVisible=false;
    const results: HTMLCollection = document.getElementsByClassName('info-list-header');
    if(!results) return;
    const header = results[0];

    const columnWidth = (100 / this.keys.length);
    let offset = 0;
    if(!!this.withEdition) offset +=2;
    if(!!this.withDelete) offset +=2;
    offset = columnWidth * (offset /100);

    this.styles = { 'min-width' : `${ columnWidth - offset }%`, 'max-width' : `${ columnWidth- offset }%`};
    this.keys.forEach(k=>k.isAsc=true);
  }

  ngOnChanges() {

    if(this.entries.length<=0 && this.withNoResultMessage){
      this.displayNoContentPanel=true;
      this.filteredEntries=[];

    }else{
      this.displayNoContentPanel=false;
      this.bufferVisible=true;

      this.filteredEntries = this.entries.map((entry:  any) =>
        Object.keys(entry)
          .filter((key: any) => this.keys.find(k=>k.key==key))
          .reduce((obj: any, key) => {
            obj[key] = entry[key];
            return obj;
          }, {}));
    }
  }

  ngAfterViewInit() {
    this.subscriptionchildren.changes.subscribe(t => {
      this.ngForRendred();
    })
  }

  ngForRendred() {
    if(this.bufferVisible){
      this.bufferVisible=false
      this.cdRef.detectChanges();
    }
  }

  handleSort(key: string) {
    let entrykey:KeyEntry = new KeyEntry;
    this.keys.forEach(k=>{
      if(k.key==key)
        entrykey=k;
      else
        k.isAsc=false;
    });
    entrykey.isAsc=!entrykey.isAsc;
    this.onSort.emit({key, order: entrykey.isAsc});

  }

   handleFilterChange(filter: string) {
    this.onFilterChange.emit(filter.trim());
  }

  handleFilterSubmit(filter:string){
    if(filter==undefined || filter==null)
      return;

    this.displayNoContentPanel=false;
    this.onFilterChange.emit(filter.trim());
  }

  handleDelete(event: Event, idx: number) {
    event.stopPropagation();
    this.onDelete.emit(idx);
  }

  handleEdition(event: Event, idx: number) {
    event.stopPropagation();
    this.onEdition.emit(idx);
  }

  handleSelect(idx: number) {
    let $rowsList = $('.rows .row');

    //on clique sur celui dejà selectionné-->on ne fait rien
    if( $($rowsList[idx]).hasClass('selected'))
      return;
    else{
      //déselectionne tout
      if(this.currentSelectionIndex>-1)
       $($rowsList[this.currentSelectionIndex]).removeClass('selected');

      //Sélectionne le bon
      $($rowsList[idx]).addClass('selected');
      this.currentSelectionIndex=idx;
      //emit
      this.onSelect.emit(idx);
    }
  }

  handlePaging(event: PageEvent) {
    this.onPaging.emit({
      limit: event.pageSize,
      offset: event.pageSize * (event.pageIndex)
    });
  }

  handleExcelExport(evt : Event) {
    this.onExportToExcel.emit();
   }

  clearSelection():void{
    if(this.currentSelectionIndex>-1){
      let $rowsList = $('.rows .row');
      $($rowsList[this.currentSelectionIndex]).removeClass('selected');
      this.currentSelectionIndex=-1;
    }
  }

  resetPaginator = () => {
    if(this.paginator) this.paginator.pageIndex = 0;
  }


}
