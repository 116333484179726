import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from "../../../_models/_base/generics";
import { Indicator, IndicatorGroup } from "../../../_models/apis/reporting";
import { environment } from "../../../../environments/environment";
import { REPORTING_OPERATIONAL_ENDPOINTS } from "../../../_helpers/routes";
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class IndicatorGroupsService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.API_URLS.smag_reporting_operational}${REPORTING_OPERATIONAL_ENDPOINTS.indicator_groups}`
  }

  get(): Observable<IndicatorGroup[]> {
    return this.http.get<PaginatedResponse<IndicatorGroup>>(this.baseUrl).pipe(map(x => x.Results as IndicatorGroup[]));
  }
  addIndicatorToGroup(indicator: Indicator, group: string){
    return this.http.put<Guid>(`${this.baseUrl}/${group}/indicators/${indicator.Id}`, "")
  }
}
