import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, AbstractControl, FormControl } from "@angular/forms";
import { Application, Solution } from '../../_models/apis/application';
import { Client, ClientType } from "../../_models/apis/party";
import { Indicator, IndicatorGroup, Measure } from "../../_models/apis/reporting";
import moment from 'moment';

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {

  @Input() indicatorFormGroup: any = this.formBuilder.group({
    selectedDate: new UntypedFormControl(moment(), Validators.required),
    selectedClientType: "",
    selectedClient: "",
    selectedSolution: "",
    selectedApplication: "",
    selectedIndicatorGroup: new UntypedFormControl("", Validators.required),
    selectedIndicator: new UntypedFormControl("", Validators.required),
    selectedMeasureType: new UntypedFormControl("", Validators.required),
    selectedValue: new FormControl<number>(0, Validators.required),
  });

  @Input() identifier: number = 0;
  @Input() clientTypes: ClientType[] = [];
  @Input() solutions: Solution[] = [];
  @Input() indicatorGroups: IndicatorGroup[] = [];
  @Input() measures: Measure[] = [];
  @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
  @Output() onCopy = new EventEmitter<any>();

  private _clients: Client[] = [];

  public get clients(): Client[] {
    return this._clients;
  }

  @Input() public set clients(value: Client[]) {
    this._clients = value;
    this.filteredClients = this.clients;
  }

  private _applications: Application[] = [];

  public get applications(): Application[] {
    return this._applications;
  }

  @Input() public set applications(value: Application[]) {
    this._applications = value;
    this.filteredApplications = this.applications;
  }

  private _indicators: Indicator[] = [];

  public get indicators(): Indicator[] {
    return this._indicators;
  }

  @Input() public set indicators(value: Indicator[]) {
    this._indicators = value;
    this.filteredIndicators = this.indicators;
  }

  public readonly binIconPath: string = "https://smagstorage.blob.core.windows.net/images/icon/Ico_Supprimer.svg";
  public readonly copyIconPath: string = "https://smagstorage.blob.core.windows.net/images/icon/Ico_Dupliquer.svg";
  public isHover: boolean = false;
  filteredApplications: Application[] = [];
  filteredIndicators: Indicator[] = [];
  filteredClients: Client[] = [];

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    if((this.indicatorFormGroup.get('selectedIndicatorGroup')?.value ?? "" != ""))
    {
      this.filteredIndicators = this.indicators.filter(indic => (this.indicatorGroups.find((group: IndicatorGroup) => group.Id == this.indicatorFormGroup.get('selectedIndicatorGroup')?.value ?? "")?.Indicators ?? [])
    .includes(indic.Id.toString()));
    }
    if((this.indicatorFormGroup.get('selectedSolution')?.value ?? "" != ""))
    {
      this.filteredApplications = this.applications.filter(app => (this.solutions.find((solution: Solution) => solution.Id == this.indicatorFormGroup.get('selectedSolution')?.value ?? "")?.Applications ?? [])
      .includes(app.Id.toString()));
    }
    if((this.indicatorFormGroup.get('selectedClientType')?.value ?? "" != ""))
    {
      this.filteredClients = this.clients.filter(client => client.CompanyType == this.indicatorFormGroup.get('selectedClientType')?.value ?? "");
    }
  }

  handleClientTypeSelection (event: any) {
    this.filteredClients = this.clients.filter(client => client.CompanyType == event.value);
    this.indicatorFormGroup.get('selectedClient').setValue("");
  }

  handleSolutionSelection (event: any) {
    this.filteredApplications = this.applications.filter(app => (this.solutions.find((solution: Solution) => solution.Id == event.value)?.Applications ?? [])
    .includes(app.Id.toString()));
    this.indicatorFormGroup.get('selectedApplication').setValue("");
  }

  handleIndicatorGroupSelected (event : any){
    this.filteredIndicators = this.indicators.filter(indic => (this.indicatorGroups.find((group: IndicatorGroup) => group.Id == event.value)?.Indicators ?? [])
    .includes(indic.Id.toString()));
    this.indicatorFormGroup.get('selectedIndicator').setValue("");
  }
  handleIndicateurSelection (event : any){
    if(this.indicatorFormGroup.get('selectedIndicatorGroup').value == ""
    || this.indicatorFormGroup.get('selectedIndicatorGroup').value == null ){
      this.filteredIndicators = this.indicators;
    }
  }
  handleApplicationSelection (event : any){
    if(this.indicatorFormGroup.get('selectedSolution').value == ""
    || this.indicatorFormGroup.get('selectedSolution').value == null){
      this.filteredApplications = this.applications;
    }
  }
  handleClientSelection (event : any){
    if(this.indicatorFormGroup.get('selectedClientType').value == ""
    || this.indicatorFormGroup.get('selectedClientType').value == null){
      this.filteredClients = this.clients;
    }
  }

  handleCopy = (event: Event) => {
    event.preventDefault();
    var result = {
      selectedDate: new UntypedFormControl(moment(new Date(this.indicatorFormGroup.get('selectedDate')?.value ?? "")), Validators.required),
      selectedClientType: this.indicatorFormGroup.get('selectedClientType')?.value ?? "",
      selectedClient: this.indicatorFormGroup.get('selectedClient')?.value ?? "",
      selectedSolution: this.indicatorFormGroup.get('selectedSolution')?.value ?? "",
      selectedApplication: this.indicatorFormGroup.get('selectedApplication')?.value ?? "",
      selectedIndicatorGroup: new UntypedFormControl(this.indicatorFormGroup.get('selectedIndicatorGroup')?.value ?? "", Validators.required),
      selectedIndicator: new UntypedFormControl(this.indicatorFormGroup.get('selectedIndicator')?.value ?? "", Validators.required),
      selectedMeasureType: new UntypedFormControl(this.indicatorFormGroup.get('selectedMeasureType')?.value ?? "", Validators.required),
      selectedValue: new FormControl<number>(this.indicatorFormGroup.get('selectedValue')?.value ?? 0, Validators.required),
    }
    this.onCopy.emit(result);
  }

  handleDeletion = (event: Event) => {
    event.preventDefault();
    this.onDelete.emit(this.identifier);
  }
}
