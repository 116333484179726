
  <div class = "info-message">
    Le fichier à importer doit être de type CSV avec des points virgules comme séparateur. Veuillez suivre le fichier exemple : <a href="https://smagstorage.blob.core.windows.net/csv/exemple_reporting.csv">exemple_reporting.csv</a>
  </div>

  <div class="container">
    <form class="form">
      <div class="file-upload-wrapper">
        <input name="file-upload-field" #hiddenfileselector class="file-upload-field" type ="file" accept=".csv" multiple="true"(change)="handleFileInput($event)" (click)="onInputClick($event)"/>
      </div>
      <input type="button" class="custom-button flat" (click)="hiddenfileselector.click()" value="Sélectionner un ficher">
    </form>
    <div class="actionSubmit">
      <primary-btn type="button" (click)="handleSubmitCSV()" text="Importer"></primary-btn>
    </div>
  </div>
<div *ngFor="let fileName of filesNames" class="uploaded">
  <app-upload [fileName]="fileName" (onDelete)="handleDeleteCSV($event)"></app-upload>
</div>
