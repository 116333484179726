import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AUTHENTICATION_OPERATIONAL_ENDPOINTS } from "../../../_helpers/routes";
import { Identity } from 'src/app/_models/apis/authentication';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.API_URLS.smag_authentication_operational}`
  }

  getLoginSignUpPage = (isLogin = true): Observable<string> => {
    const body = {
        "AdditionalValues": {
            "application_id": environment.appId,
            "displaySignUp": isLogin ? "0": "1",
            "displayLogin": isLogin ? "1": "0",
            "displayInfo": "0"
        }
    }
    return this.http.post<string>(this.baseUrl + AUTHENTICATION_OPERATIONAL_ENDPOINTS.build_url, body)
  }
  async getUserApplications() {
    const url = `${environment.API_URLS.smag_authentication_operational}${AUTHENTICATION_OPERATIONAL_ENDPOINTS.user_subscriptions}`;
    return await this.http.get(url).toPromise().then(userApp => <Identity[]> userApp);
  }
}
