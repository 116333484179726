import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
  })
  export class UploadComponent implements OnInit {
    public readonly binIconPath: string = "https://smagstorage.blob.core.windows.net/images/icon/Ico_Supprimer.svg";
    public isHover: boolean = false;
    private _fileName: String = "";
    @Output() onDelete: EventEmitter<String> = new EventEmitter<String>();

  public get fileName(): String {
    return this._fileName;
  }

  @Input() public set fileName(value: String) {
    this._fileName = value;
  }

    constructor(){
    }

    ngOnInit(): void {
    }
    handleDeleteCSV(event: Event){
      event.preventDefault();
      this.onDelete.emit(this._fileName);
    }
  }
