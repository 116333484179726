import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { REPORTING_OPERATIONAL_ENDPOINTS } from "../../../_helpers/routes";
import { Report } from "../../../_models/apis/reporting";
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient) {
  this.baseUrl = `${environment.API_URLS.smag_reporting_operational}`
  }
  createMultiple(newReports: Report[]): Observable<void> {
    const body = {
      measures: newReports
    };
    return this.http.post<void>(this.baseUrl + REPORTING_OPERATIONAL_ENDPOINTS.reports, body);
  }
  uploadReports(fichiers : FormData) : Observable<void>{
    let headers = new Headers();
    const httpOptions = {
      params: new HttpParams()
        .set("Content-Type", 'multipart/form-data')
        .set("Accept", 'application/json')
    };
    return this.http.post<void>(this.baseUrl + REPORTING_OPERATIONAL_ENDPOINTS.upload, fichiers, httpOptions);
  }
}
