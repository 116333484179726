import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, AbstractControl, Validators, FormControl} from "@angular/forms"
import { ClientsService, ClientTypesService } from "../../_services/apis/party";
import { ApplicationsService, SolutionsService } from "../../_services/apis/application"
import { IndicatorsService, IndicatorGroupsService, ReportsService, MeasuresService } from "../../_services/apis/reporting";
import { NotifierService } from "../../_services/ui/notifier.service";
import { Client, ClientType } from "../../_models/apis/party";
import { Solution, Application } from "../../_models/apis/application";
import { Indicator, IndicatorGroup, Measure, Report } from "../../_models/apis/reporting";
import { NotificationType } from 'src/app/_models/ui/notification';
import moment from 'moment';
import { Commons } from 'src/app/_helpers/commons';
import { PaginatedResponse } from 'src/app/_models/_base/generics';
import { SmagBufferService } from "@smag-cloud/smag-components"
import { INFO_MESSAGES } from "../../_helpers/messages";

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent implements OnInit {

  public clients: Client[] = [];
  public keepDate: boolean = true;
  public clientTypes: ClientType[] = [];
  public applications: Application[] = [];
  public solutions: Solution[] = [];
  public indicators: Indicator[] = [];
  public indicatorGroups: IndicatorGroup[] = [];
  public measures: Measure[] = [];
  @Input() menuToShow: String = "manuelle";

  public indicatorFormArray: UntypedFormArray = this.formBuilder.array([this.formBuilder.group({
    selectedDate: new UntypedFormControl(moment(this.getDateDefault()), Validators.required),
    selectedClientType: "",
    selectedClient: "",
    selectedSolution: "",
    selectedApplication: "",
    selectedIndicatorGroup: new UntypedFormControl("", Validators.required),
    selectedIndicator: new UntypedFormControl("", Validators.required),
    selectedMeasureType: new UntypedFormControl("", Validators.required),
    selectedValue: new FormControl<number>(0, Validators.required)
  })]);
  public indicatorsForm = this.formBuilder.group({indicatorFormArray: this.indicatorFormArray});

  constructor(
    private formBuilder: UntypedFormBuilder,
    private clientService: ClientsService,
    private clientTypesService: ClientTypesService,
    private applicationsService: ApplicationsService,
    private solutionsService: SolutionsService,
    private indicatorsService: IndicatorsService,
    private indicatorGroupsService: IndicatorGroupsService,
    private measuresSerice: MeasuresService,
    private reportsService: ReportsService,
    private notifierService: NotifierService,
    private common: Commons,
    private bufferService: SmagBufferService
    ) { }

  ngOnInit(): void {
    this.clientService.get(this.common.max_number_value).subscribe((clients: PaginatedResponse<Client>) => this.clients = clients.Results
    .sort((n1,n2) =>
        (n1.Name.toLocaleLowerCase() > n2.Name.toLocaleLowerCase())?1:-1
    ));
    this.clientTypesService.get(10).subscribe((apps: PaginatedResponse<ClientType>) => this.clientTypes = apps.Results);
    this.applicationsService.get(this.common.max_number_value).subscribe((apps: PaginatedResponse<Application>) => this.applications = apps.Results);
    this.solutionsService.get(this.common.max_number_value).subscribe((solutions: PaginatedResponse<Solution>) => this.solutions = solutions.Results);
    this.indicatorsService.get(this.common.max_number_value).subscribe((indicators: Indicator[]) => this.indicators = indicators
    .sort((n1,n2) =>
      (n1.Name.toLocaleLowerCase() > n2.Name.toLocaleLowerCase())?1:-1
    ));
    this.indicatorGroupsService.get().subscribe((indicatorGroups: IndicatorGroup[]) => this.indicatorGroups = indicatorGroups
    .sort((n1,n2) =>
      (n1.Name.toLocaleLowerCase() > n2.Name.toLocaleLowerCase())?1:-1
    ));
    this.measuresSerice.get().subscribe((measures: Measure[])=> this.measures = measures .sort((n1,n2) =>
      (n1.Name.toLocaleLowerCase() > n2.Name.toLocaleLowerCase())?1:-1
    ));
  }
  getDateDefault(): Date{
    const date = new Date(Date.now());
    date.setMonth(date.getMonth()-1);
    date.setFullYear(date.getFullYear());
    return date;
  }
  handleDelete = (idx: number) => {
    this.indicatorFormArray.removeAt(idx);
  }

  handleCopy = (data: any) =>
  {
    this.indicatorFormArray.push(this.formBuilder.group(data));
  }

  handleAdd = (evt: Event) => {
    evt.preventDefault();
    this.indicatorFormArray.push(this.formBuilder.group({
      selectedDate: new UntypedFormControl(moment(this.getDateDefault()), Validators.required),
      selectedClientType: "",
      selectedClient: "",
      selectedSolution: "",
      selectedApplication: "",
      selectedIndicatorGroup: new UntypedFormControl("", Validators.required),
      selectedIndicator: new UntypedFormControl("", Validators.required),
      selectedMeasureType: new UntypedFormControl("", Validators.required),
      selectedValue: new FormControl<number>(0, Validators.required)
    }));
  }

  handleCancel = (evt: Event) => {
    evt.preventDefault();
    this.indicatorFormArray.reset();
  }
  handleSubmit = () => {
    this.indicatorsForm.markAllAsTouched();
    if(this.indicatorFormArray.length <= 0 || this.indicatorsForm.status != "VALID") {
      this.notifierService.push({message: INFO_MESSAGES.ERROR_SUBMIT_MSG, type: NotificationType.error});
      return;
    }
    else {
      const reports: Report[] = this.getIndicatorsFromFormArray(this.indicatorFormArray);
      this.bufferService.activate(INFO_MESSAGES.LOADING_SUBMIT_MSG);
      this.reportsService.createMultiple(reports).subscribe((res) => {
        this.notifierService.push({message: INFO_MESSAGES.SUCCESS_SUBMIT_MSG, type: NotificationType.success});
        this.indicatorsForm.reset();
        this.bufferService.reset();
        this.indicatorFormArray.clear();
        },
        err => {
          this.notifierService.push({message: INFO_MESSAGES.ERROR_SUBMIT_MSG, type: NotificationType.error});
          this.bufferService.reset();
        }
      )
    }
  }

  private getIndicatorsFromFormArray(indicatorFormArray: UntypedFormArray): Report[] {
    return indicatorFormArray.controls.map((indicatorFormGroup: AbstractControl): Report => {
      return {
        Period: indicatorFormGroup.get('selectedDate')?.value ? indicatorFormGroup.get('selectedDate')?.value.format('YYYY-MM'): "",
        Company: this.clients?.find(company => company.CompanyId == indicatorFormGroup?.get('selectedClient')?.value)?.Code ?? "",
        User: '',
        Solution: this.solutions?.find(x => x.Id == indicatorFormGroup?.get('selectedSolution')?.value)?.Code ?? "",
        Application: this.applications?.find(app => app.Id == indicatorFormGroup?.get('selectedApplication')?.value)?.code ?? "",
        IndicatorGroup: this.indicatorGroups?.find(x => x.Id == indicatorFormGroup?.get('selectedIndicatorGroup')?.value)?.Code ?? "",
        Indicator:  this.indicators?.find(indic => indic.Id == indicatorFormGroup?.get('selectedIndicator')?.value)?.Code ?? "",
        Measure: indicatorFormGroup.get('selectedMeasureType')?.value ? indicatorFormGroup.get('selectedMeasureType')?.value : "",
        Numerator: indicatorFormGroup.get('selectedValue')?.value ? indicatorFormGroup.get('selectedValue')?.value: 0,
        Denominator: 1
      }
    })
  }
}
