import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {
  private isLoggedIn: boolean = false;
  private token: string = "";
  private refreshToken: string = "";
  constructor() { }

  getToken = (): string  => {
    return localStorage.getItem("token")?? "";
  };

  getRefreshToken = (): string => this.refreshToken;

  login = (newToken: string) => {
    this.token = newToken;
    localStorage.setItem("token", this.token);
    this.isLoggedIn = true;
  }

  logout = () => {
    this.token = "";
    this.refreshToken = "";
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.isLoggedIn = false;
  }

  public isConnected = () :boolean => this.isLoggedIn;

  refreshConnection = (newRefreshToken: string) => {
    this.refreshToken = newRefreshToken;
    localStorage.setItem("refreshToken", this.refreshToken)
  }

}
