import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SEARCH_BOX_MESSAGES } from 'src/app/_helpers/messages';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() pattern: any = "";
  @Input() placeholdermessage:string = "";
  @Output() onSubmit = new EventEmitter<string>();

  message=SEARCH_BOX_MESSAGES;
  public customplaceholder:string = "";


  constructor() { }

  ngOnInit() {
    this.customplaceholder = (this.placeholdermessage==undefined || this.placeholdermessage=="")?SEARCH_BOX_MESSAGES.PLACEHOLDER:this.placeholdermessage;
  }

  handleChange(filter: any){
    this.pattern = filter;
    this.onSubmit.emit(this.pattern.target.value);
  }

  handleSubmit = (event: Event) => {
    this.onSubmit.emit(this.pattern.target.value);
  }
}
