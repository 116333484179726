import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Commons } from 'src/app/_helpers/commons';
import { delay } from 'rxjs/operators';
import { SmagBufferService } from "@smag-cloud/smag-components";
import { environment } from "../../../environments/environment";
declare const SmagButton: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  smagBtn:any;
  private _isStandAlone: boolean = false;
  public obs: any = null;

  public get isStandalone(): boolean {
    return this._isStandAlone;
  }

  public set isStandalone(value: boolean) {
    this._isStandAlone = value;
  }
  constructor(
    private router: Router,
    private common: Commons,
    public smagBufferService: SmagBufferService
    ) { }

  ngOnInit(): void {
    this.isStandalone=this.common.standalone;
    this.loadSmagButtonLibraries();
    this.obs = this.smagBufferService.getVisible().pipe(delay(10));
  }

  disconnection = () => {
    localStorage.clear();
    this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url }});
  }

  private loadSmagButtonLibraries() {
    if(this.isStandalone){
      var head = document.getElementsByTagName('head')[0];

      //Check if smagbutton script is already in html head
      if(this.SmagButtonInScript(head))
        return;

      var smagbuttonscript = document.createElement('script');
      smagbuttonscript.setAttribute('id', 'smagbutton');
      smagbuttonscript.setAttribute('type', 'text/javascript');
      smagbuttonscript.setAttribute('src', environment.SMAG_BUTTON_SCRIPT+Date.now());
      smagbuttonscript.onload = this.smagButtonLoadedHandler.bind(this);

      head.appendChild(smagbuttonscript);
    }
  }

  private smagButtonLoadedHandler() {
    this.smagBtn = new SmagButton('.smagButtonContainer', localStorage.getItem("token"), localStorage.getItem("refresh_token"), environment.ApiManagerKey, true);
    this.smagBtn.addSignOutCallback(this.disconnection);
  }
  private SmagButtonInScript(header:any):Boolean{

    for(var i=0; i<header.children.length;i++){
      if(header.children[i].id=="smagbutton")
        return true;
    };

    return false;
}
}
