<app-filter *ngIf="withSearch" (onSubmit)="handleFilterSubmit($event)" ></app-filter>
<div *ngIf="remarks!=''" class="search-remarks">{{remarks}}</div>
<button type="button" *ngIf="withExcelExport" class="export-to-excel custom-button flat" mat-button (click)="handleExcelExport($event)">Export Excel</button>

<ul class="info" [ngClass]="{'with-paging': withPaging && filteredEntries.length}" *ngIf="filteredEntries" >
  <div class="buffer-container" [ngClass]="{'buffer-visible': bufferVisible}">
    <smag-buffer ></smag-buffer>
  </div>
  <div class="info-list-header">
      <span *ngFor="let key of keys" (click)="this.handleSort(key.key)" [ngStyle]="styles" class="col-infolist" [ngClass]="{'with-sort': withSort, 'asc':key.isAsc, desc:!key.isAsc}">{{key.name}}</span>
  </div>
  <div *ngIf="displayNoContentPanel" class="info-list-no-content">{{noResultMessage}}</div>
  <div class="rows" [ngClass]="{'excel-report': withExcelExport, 'with-paging': withPaging && filteredEntries.length}" >
      <li #subscriptionlistchildren class="row"
        *ngFor="let entry of filteredEntries; let rowIdx=index;"
        (click)="handleSelect(rowIdx)" id="{{ 'infolist-item-' + rowIdx }}">
        <span  [ngStyle]="styles"  class="col-infolist" *ngFor="let key of keys" matTooltip="{{key.type=='Date'?(entry[key.key]|date:'dd/MM/yyyy'):entry[key.key]}}" matTooltipDisabled="{{!withTooltip}}" matTooltipShowDelay="1000">
          {{key.type=='Date'?(entry[key.key]|date:'dd/MM/yyyy'):entry[key.key]}}
        </span>
        <button *ngIf="withEdition" class="option edit" (click)="handleEdition($event, rowIdx)"></button>
        <button *ngIf="withDelete" class="option delete" (click)="handleDelete($event, rowIdx)"></button>
      </li>
  </div>
  <mat-paginator *ngIf="withPaging && filteredEntries.length" [pageSize]="25" [length]="this.inputLengthInDB" (page)="handlePaging($event)"></mat-paginator>
</ul>
