import { Component, OnInit, AfterViewInit } from '@angular/core';
import $ from 'jquery';
import { Router, NavigationStart, Event } from '@angular/router';
import { MenuEntry } from 'src/app/_models/_base/Menu';
import  MenuData  from './MenuJson.json';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {

  public displayableMenu:MenuEntry[]=[];
  private menuItemWidth:number=160;

  constructor(private router: Router) { }

  ngOnInit() {
    this.displayableMenu = MenuData;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          localStorage.setItem("reporting.current.menu.route", event.url);
      }
    })
  }

  //once the view is loaded, select a given menu entry
  ngAfterViewInit(): void {
    var menu = $('.reporting-menu-item');
    if(menu && menu.length>0){}

    //On active la derniere route consultée
    var entryToSelect:MenuEntry, indexToSelect:number, routeToSelect:string="";
    let menuEntries:MenuEntry[] = MenuData;
    ;

    if(menuEntries && menuEntries.length>0){
      routeToSelect = localStorage?.getItem("reporting.current.menu.route") ?? "";
      entryToSelect = menuEntries?.find(m=>m.route==routeToSelect) ?? new MenuEntry;
      indexToSelect = entryToSelect ? entryToSelect.index: -1;//On recupere l'index du menu(niveau1)
        // si aucun index n'es sélectionner
        if(indexToSelect != -1) {
          this.activateMenu(indexToSelect, routeToSelect);
          this.router.navigate([routeToSelect]);
        }
      }
    }

  //activate selected (clicked) menu entry
  //Mets en evidence le menu (niveau1) qui est selectionné
  activateMenu(idx:number, route:string, isSubmenu:boolean=false, withRouting: boolean = true) {
    const htmlMenu = $('.reporting-menu-item');
    var that = this;
    htmlMenu.each((i:number, item: any) => {
      const $item = $(item);
      const menuentry:MenuEntry= that.displayableMenu[i];
      if(idx!= menuentry.index)
        $item.removeClass('active');
      else{
        $item.addClass('active');
        $('.marker').css('width','16rem');
        $('.marker').css('left',that.menuItemWidth*i + 50);
        localStorage.setItem("reporting.current.menu.index",idx.toString());
        localStorage.setItem("reporting.current.menu.route",route);
      }
      if(idx == -1) {
        localStorage.removeItem('reporting.current.menu.route');
        $('.marker').css('left',0);
        $('.marker').css('width','5rem');
      }
    });
    if(withRouting) this.router.navigate([route]);
  }
}
