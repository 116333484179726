<div class="indicator-content">
    <form *ngIf="indicator" (ngSubmit)="handleSubmit()" [formGroup]="indicatorFormGroup">
        <div class="fields">
            <mat-form-field class="wide readonly" *ngIf="indicator.Id !='00000000-0000-0000-0000-000000000000' ">
                <input name="id" matInput formControlName="idFf"
                    placeholder="{{html_messages.FIELD_PLACEHOLDER_ID}}" [readonly]="true">
            </mat-form-field>
            <mat-form-field class="short">
                <input name="name" matInput formControlName="selectedNameFf"
                    placeholder="{{html_messages.FIELD_PLACEHOLDER_NAME}}">
            </mat-form-field>
            <mat-form-field class="short">
                <input name="code" matInput formControlName="selectedCodeFf"
                    placeholder="{{html_messages.FIELD_PLACEHOLDER_CODE}}">
            </mat-form-field>
            <mat-form-field class="short indicator-groups-field" appearance="fill">
                <mat-label>{{html_messages.FIELD_PLACEHOLDER_INDICATOR_GROUP}}</mat-label>
                <mat-select name="indicator_group" formControlName="selectedIndicatorGroupFf">
                  <mat-option *ngFor="let indicatorGroup of indicatorGroups" [value]="indicatorGroup.Id">
                    {{indicatorGroup.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <app-action-row (onValidate)="handleSubmit()" (onCancel)="back()"></app-action-row>
    </form>
</div>
