import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment"
import { REPORTING_OPERATIONAL_ENDPOINTS } from "../../../_helpers/routes";
import { PaginatedResponse } from "../../../_models/_base/generics";
import { Indicator } from "../../../_models/apis/reporting";

@Injectable({
  providedIn: 'root'
})
export class IndicatorsService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.API_URLS.smag_reporting_operational}${REPORTING_OPERATIONAL_ENDPOINTS.indicators}`
  }

  get(limit: number = 25, offset: number = 0): Observable<Indicator[]> {
    const httpOptions = {
      params: new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString())
    }
    return this.http.get<PaginatedResponse<Indicator>>(this.baseUrl, httpOptions).pipe(map(x => x.Results as Indicator[]));
  }
  create (newIndicator: Indicator): Observable<Indicator>{
    return this.http.post<Indicator>(this.baseUrl, newIndicator);
  }
  update (updatedIndicator: Indicator): Observable<Indicator>{
    return this.http.put<Indicator>(`${this.baseUrl}/${updatedIndicator.Id.toString()}`, updatedIndicator);
  }
}
