import { Component, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { SmagBufferService } from "@smag-cloud/smag-components";
import { Commons } from 'src/app/_helpers/commons';
import { INDICATORS_MESSAGES, INFO_MESSAGES } from 'src/app/_helpers/messages';
import { Indicator, IndicatorGroup } from 'src/app/_models/apis/reporting';
import { NotificationType } from 'src/app/_models/ui/notification';
import { IndicatorGroupsService, IndicatorsService } from 'src/app/_services/apis/reporting';
import { NotifierService } from 'src/app/_services/ui/notifier.service';

@Component({
  selector: 'app-create-indicator',
  templateUrl: './create-indicator.component.html',
  styleUrls: ['./create-indicator.component.scss']
})
export class CreateIndicatorComponent implements OnInit {

  public indicators: Indicator[] = [];
  public indicatorGroups: IndicatorGroup[] = [];
  public backUpIndicators: Indicator[] = [];
  public selectedGroup: IndicatorGroup = new IndicatorGroup();
  isUpdate: boolean = true;
  selectedIndex: number = -1;
  selectedIndicator: Indicator = new Indicator();
  isDetail: boolean = false;
  backupIndicator: Indicator = new Indicator();

  constructor(private bufferService: SmagBufferService,
    private indicatorsService: IndicatorsService,
    private common: Commons,
    private indicatorGroupsService: IndicatorGroupsService,
    private notifierService: NotifierService
    ) { }

  ngOnInit(): void {
    this.init();
  }
  async init(){
    this.bufferService.activate(INDICATORS_MESSAGES.LOAD_DATA_INDICATORS);
    this.indicatorsService.get(this.common.max_number_value).subscribe((indicators: Indicator[]) =>
    {this.indicators = indicators
    .sort((n1,n2) =>
      (n1.Name.toLocaleLowerCase() > n2.Name.toLocaleLowerCase())?1:-1
    )
      this.backUpIndicators = this.indicators;
      this.bufferService.reset();
    });
    this.bufferService.activate(INDICATORS_MESSAGES.LOAD_DATA_INDICATORS_GROUPS);
    this.indicatorGroupsService.get().subscribe((indicatorGroups: IndicatorGroup[]) => this.indicatorGroups = indicatorGroups
    .sort((n1,n2) =>
      (n1.Name.toLocaleLowerCase() > n2.Name.toLocaleLowerCase())?1:-1
    ));
    if (this.selectedIndex > -1){
      this.selectedIndicator = this.indicators[this.selectedIndex];
      this.bufferService.reset();
    }

  }
  onIndicatorAdd(){
    this.selectedIndex = -1;
    this.selectedIndicator = new Indicator();
    this.selectedGroup = this.indicatorGroups[0];
    this.backupIndicator = JSON.parse(
      JSON.stringify(this.selectedIndicator)
    );
    this.isDetail = true;
    this.isUpdate = false;
  }
  onFilterChange(filter: string) {
    this.indicators = this.backUpIndicators.filter(
      indic =>
        RegExp(`${filter.toLocaleLowerCase()}`).exec(
          indic.Name.toLocaleLowerCase()
        ) ||
        RegExp(`${filter.toLocaleLowerCase()}`).exec(
          indic.Code.toLocaleLowerCase()
        )
        ||
        RegExp(`${filter.toLocaleLowerCase()}`).exec(
          indic.Id.toString().toLocaleLowerCase()
        )
    );
  }
  onSelect(index: number): void {
    this.selectedIndex = index;
    this.isUpdate = true;
    this.selectedIndicator = this.indicators[index];
    this.backupIndicator = JSON.parse(
      JSON.stringify(this.selectedIndicator)
    );
    this.selectedGroup = this.indicatorGroups?.find((group: IndicatorGroup) => group.Indicators.find(indic => indic.toString().toUpperCase() == this.selectedIndicator.Id.toString().toUpperCase())) ?? new IndicatorGroup();
    this.isDetail = true;
  }
  indicatorBackEvent(): void {
    if (this.selectedIndicator.Id.toString() != "" && this.selectedIndicator.Id.toString() != Guid.EMPTY) {
      if (!this.resetApplication(this.indicators[this.selectedIndex]))
        this.isDetail = false;
    } else {
      this.selectedIndicator = new Indicator();
      this.backupIndicator = this.selectedIndicator;
      this.isDetail = false;
    }
  }
  private resetApplication(initialIndic: Indicator): boolean {
    let changed: boolean = false;

    changed = !changed
      ? initialIndic.Name !== this.backupIndicator.Name
      : changed;
      initialIndic.Name = this.backupIndicator.Name;

    changed = !changed
      ? initialIndic.Code !== this.backupIndicator.Code
      : changed;
      initialIndic.Code = this.backupIndicator.Code;

    return changed;
  }
  addIndicToGroup(idGroup : string, indic: Indicator)
  {
    this.bufferService.activate(INFO_MESSAGES.LOADING_SUBMIT_MSG);
    this.indicatorGroupsService.addIndicatorToGroup(indic, idGroup).subscribe((res) => {
        this.notifierService.push({message: INFO_MESSAGES.SUCCESS_SUBMIT_MSG, type: NotificationType.success});
        this.bufferService.reset();
        this.indicatorGroups.find((group: IndicatorGroup) => group.Id.toString().toUpperCase() == idGroup.toUpperCase())?.Indicators.push(indic.Id);
      },
      err => {
        this.notifierService.push({message: INDICATORS_MESSAGES.ERROR_ADD_GROUP, type: NotificationType.error});
        this.bufferService.reset();
      });
  }
  handleChange(indic: any): void {
    var indicToSend = new Indicator();
    indicToSend.Id = indic.IndicId;
    indicToSend.Name = indic.IndicName;
    indicToSend.Code = indic.IndicCode;
    if (this.isUpdate) {
      this.bufferService.activate(INFO_MESSAGES.LOADING_SUBMIT_MSG);
      this.indicatorsService.update(indicToSend).subscribe((res) => {
        this.notifierService.push({message: INFO_MESSAGES.SUCCESS_SUBMIT_MSG, type: NotificationType.success});
        this.bufferService.reset();
        if (res) {
          this.addIndicToGroup(indic.Group, indicToSend);
          var indicToUpdate = this.indicators.find((indic: Indicator) => indic.Id.toString().toUpperCase() == indicToSend.Id.toString().toUpperCase());
          indicToUpdate ? indicToUpdate.Name = indicToSend.Name : "";
          indicToUpdate ? indicToUpdate.Code = indicToSend.Code : "";
        }
      },
      err => {
        this.notifierService.push({message: INFO_MESSAGES.ERROR_SUBMIT_MSG, type: NotificationType.error});
        this.bufferService.reset();
      });
    }
    else{
      this.indicatorsService.create(indicToSend).subscribe((res) => {
        this.notifierService.push({message: INFO_MESSAGES.SUCCESS_SUBMIT_MSG, type: NotificationType.success});
        this.bufferService.reset();
       if (res) {
        indicToSend.Id = res.Id;
        this.addIndicToGroup(indic.Group, indicToSend);
        this.indicators.push(indicToSend);
        }
      },
      err => {
        this.notifierService.push({message: INFO_MESSAGES.ERROR_SUBMIT_MSG, type: NotificationType.error});
        this.bufferService.reset();
      });
    }
    this.isUpdate = true;
    this.selectedIndicator = indicToSend;
    this.backupIndicator = this.selectedIndicator;
  }
}
