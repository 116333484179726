<ul class="form-header">
    <li>Periode</li>
    <li>Types de <br> Client</li>
    <li>Clients</li>
    <li>Solutions</li>
    <li>Applications</li>
    <li>Groupes <br> d'indicateur</li>
    <li>Indicateurs</li>
    <li>Types <br> de mesure</li>
    <li>Valeur</li>
    <li class="option">...</li>
</ul>
