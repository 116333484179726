export enum NotificationType {
    success = "success",
    error = "error",
    warning = "warning",
}

export interface Notification {
    type: NotificationType;
    message: string;
}
