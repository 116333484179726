import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { INDICATORS_MESSAGES, INDICATOR_HTML_MESSAGES, VALIDATION_MESSAGES } from 'src/app/_helpers/messages';
import { Indicator, IndicatorGroup } from 'src/app/_models/apis/reporting';
import { NotificationType } from 'src/app/_models/ui/notification';
import { NotifierService } from 'src/app/_services/ui/notifier.service';

@Component({
  selector: 'app-indicator-details',
  templateUrl: './indicator-details.component.html',
  styleUrls: ['./indicator-details.component.scss']
})
export class IndicatorDetailsComponent implements OnInit, OnChanges {

  constructor(private formBuilder: UntypedFormBuilder,
    private notifierService: NotifierService
    ) { }

  @Input() indicator: Indicator = new Indicator();
  @Input() indicatorGroups: Array<IndicatorGroup> = [];
  @Input() selectedGroup: IndicatorGroup = new IndicatorGroup();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBack: EventEmitter<void> = new EventEmitter<void>();

  indicatorFormGroup = new UntypedFormGroup({
     idFf : new UntypedFormControl(this.indicator?.Id),
     selectedNameFf : new UntypedFormControl(this.indicator?.Name ?? "", Validators.required),
     selectedCodeFf : new UntypedFormControl(this.indicator?.Code ?? "", Validators.required),
     selectedIndicatorGroupFf : new UntypedFormControl(this?.selectedGroup.Name ?? "", Validators.required)
  });

  html_messages = INDICATOR_HTML_MESSAGES;
  public formRequiredMessage = VALIDATION_MESSAGES.REQUIRED_MESSAGE;
  public group: IndicatorGroup = new IndicatorGroup();
  public previousGroup: IndicatorGroup = new IndicatorGroup();

  ngOnInit(): void {
  }

  ngOnChanges():void{
    if(this.indicator!=null){
      this.indicatorFormGroup.controls["idFf"].setValue(this.indicator.Id);
      this.indicatorFormGroup.controls["selectedNameFf"].setValue(this.indicator.Name);
      this.indicatorFormGroup.controls["selectedCodeFf"].setValue(this.indicator.Code);
      this.indicatorFormGroup.controls["selectedIndicatorGroupFf"].setValue(this?.selectedGroup.Id ?? "");
    }
  }

  public handleSubmit = () => {
    this.indicatorFormGroup.markAllAsTouched();

    if (this.indicatorFormGroup.status != "VALID") {
      this.notifierService.push({message: INDICATORS_MESSAGES.ERROR_REQUIRED, type: NotificationType.error});
    }
    else{

      var indicatorIndicatorGroup = {
        IndicId: this.indicatorFormGroup.controls["idFf"].value,
        IndicName: this.indicatorFormGroup.controls["selectedNameFf"].value,
        IndicCode:  this.indicatorFormGroup.controls["selectedCodeFf"].value,
        Group: this.selectedGroup.Id,
        PreviousGroup: this.previousGroup.Id
      };
      this.onSubmit.emit(indicatorIndicatorGroup);
    }
  }


  public back = () => this.onBack.emit();
}
