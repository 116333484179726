export const APPLICATION_OPERATIONAL_ENDPOINTS =  {
  applications: "api/applications",
  solutions: "api/solutions"
}

export const PARTY_OPERATIONAL_ENDPOINTS = {
    clients: "api/companies",
    client_types: "api/company_types"
}

export const AUTHENTICATION_OPERATIONAL_ENDPOINTS = {
  build_url: "api/authentication/build_url",
  refresh_token: "api/oauth2/refresh_token",
  subscription: "api/subscriptions",
  user_subscriptions :"api/subscriptions/current_user"
}

export const REPORTING_OPERATIONAL_ENDPOINTS = {
  indicators: "indicators",
  indicator_groups: "indicatorGroups",
  reports: "reports",
  upload: "reports/upload",
  measures: "measures"
}

export const CLAIMS_KEY = {
  EMAIL: "https://smag.authentication.operational.com/email",
  IDENTITIES: "https://smag.authentication.operational.com/identities",
  USERID: "https://smag.authentication.operational.com/userId",
}
