<div class="indicators-panel">
    <div class="indicators-container">
      <div class="grid" [ngClass]="{'detail': isDetail}">
        <div class="infolist grid-item left" *ngIf="indicators">
          <app-infolist
            (onSelect)="onSelect($event)"
            (onFilterChange)="onFilterChange($event)"
            [withSort]="false"
            [withSearch]="true"
            [withNoResultMessage]="true"
            [withExcelExport]="false"
            [withDelete]="false"
            [entries]="indicators"
            [keys]="[{key:'Name', name:'Nom'}, {key:'Code',name:'Code'}]">
          </app-infolist>
        </div>
        <app-indicator-details
          class="content-block grid-item right"
          [indicator]="selectedIndicator"
          [indicatorGroups]="indicatorGroups"
          [selectedGroup]="selectedGroup"
          (onSubmit)="handleChange($event)"
          (onApplicationChange)="handleChange($event)"
          (onBack)="indicatorBackEvent()">
        </app-indicator-details>
      </div>
   </div>
   <add-btn (click)="onIndicatorAdd()"></add-btn>
 </div>
