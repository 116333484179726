import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { APPLICATION_OPERATIONAL_ENDPOINTS } from "../../../_helpers/routes";
import { Application } from "../../../_models/apis/application";
import { Observable } from 'rxjs';
import { PaginatedResponse } from 'src/app/_models/_base/generics';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.API_URLS.smag_application_operational}${APPLICATION_OPERATIONAL_ENDPOINTS.applications}`;
  }

  get = (limit: number = 25, offset: number = 0): Observable<PaginatedResponse<Application>> => {

    //added query params to the http request
    const httpOptions = {
      params: new HttpParams()
        .set("limit", limit.toString())
        .set("offset", offset.toString())
    };

    return this.http.get<PaginatedResponse<Application>>(this.baseUrl, httpOptions);
  }

}
